import React, { useEffect, useState } from 'react'
import { LayoutWrapper } from '../components/layout/layoutWrapper'
import { Seo } from '../components/seo/Seo'
import { useNavigationItems } from '../hooks/useNavigationItems'
import { useTranslations } from '../hooks/useTranslations'
import { navigate } from 'gatsby'
import { useLocale } from '../hooks/useLocale'

const NotFound: React.FC = () => {
  const {
    common: { notFound: translations },
  } = useTranslations()

  const currentSlug = '/404'
  const { pageLocale: currentLocale } = useLocale()
  const seoTitle = translations.title
  const seoDescription = translations.description

  const [message, setMessage] = useState('')
  const { collectBreadCrumbs } = useNavigationItems()
  const currentPagePath = collectBreadCrumbs({ slug: currentSlug, title: seoTitle, type: 'EditorialPages' })

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // set the error message
      if (window.location.href.match('/hochschulen/.+|/en/higher-educations-institutes/.+')) {
        setMessage(translations.organizationPage)
      } else if (window.location.href.match('/weiterbildungsangebote/.+|/en/further-training/.+')) {
        setMessage(translations.coursePage)
      } else {
        let prefix = ''
        if (currentLocale === 'en') {
          prefix = '/en'
        }
        // reroute if not already on the /404 page
        if (window.location.href !== `${prefix}${currentSlug}`) {
          // replace the current url with a 404 one
          navigate(`${prefix}${currentSlug}`, { replace: true })
        }
        setMessage(translations.otherPage)
      }
    }
  }, [translations, currentLocale])

  return (
    <>
      <Seo title={seoTitle} slug={currentSlug} locale={currentLocale} description={seoDescription}></Seo>
      <LayoutWrapper
        currentLocale={currentLocale}
        currentTitle={seoTitle}
        currentSlug={currentSlug}
        currentPath={currentPagePath}
        otherLocales={[{ locale: 'en', slug: '/en/404/' }]}
      >
        <h1>{message}</h1>
      </LayoutWrapper>
    </>
  )
}

export default NotFound
